import React from "react"
//import { Link } from "gatsby"
//import styles from "./404.module.css"

import SEO from "../components/seo"
import Header from "../components/layout/js/header"
import Overlay from "../components/layout/js/overlay"
import Contacter from "../components/layout/js/contacter"
import Footer from "../components/layout/js/footer"
import Copyrights from "../components/layout/js/copyrights"

const Mentions = () => (
  <>
    <SEO title="Formulaire envoyé" />
    <Header />
    <Overlay />
    <div className="first w-75-ns ph3 center">
      <p>
        <span>
          Si vous utilisez nos Mentions Légales, vous vous engagez à laisser les
          liens 
        </span>
        <strong>
          <span>Crédit sans en modifier ni les liens ni les ancres.</span>
        </strong>
        <span> </span>
        <br />
        <span>
          <span>
            Par avance merci pour votre compréhension et le respect du travail
            effectué par toute l'équipe
          </span>
        </span>
      </p>

      <h1>MENTIONS LEGALES :</h1>

      <p>
        <br />
        Conformément aux dispositions des articles 6-III et 19 de la Loi n°
        2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique,
        dite L.C.E.N., nous portons à la connaissance des utilisateurs et
        visiteurs du site :
        <a href="http://www.fany-glass.fr" target="_blank">
          www.fany-glass.fr
        </a>
        les informations suivantes :
      </p>

      <p>
        <strong>ÉDITEUR</strong>
      </p>

      <p>
        Le site
        <a href="http://www.fany-glass.fr" target="_blank">
          www.fany-glass.fr
        </a>
         est la propriété exclusive de <strong>SARL </strong>
        <strong>ATELIER VITRAIL-FANY GLASS</strong>, qui l'édite.
      </p>

      <p>
        <strong>ATELIER VITRAIL-FANY GLASS</strong>
        <strong> </strong>
        <br />
        <strong>SARL </strong>au capital de<strong> </strong>
        <strong>8000 </strong>€Tél  : <strong>0976364656</strong>
      </p>

      <p>
        <strong>1 RUE DU PARC </strong>
        <strong>95300 ENNERY</strong>
        <br />
        Immatriculée au Registre du Commerce et des Sociétés de  
        <strong>Immatriculée au RCS le 19-06-1996 </strong>sous le numéro
        <strong> </strong>
        <strong>40757011800049</strong>
        <strong> </strong>
      </p>

      <p>
        Numéro TVA intracommunautaire : <strong>679867</strong>
        <br />
        Adresse de courrier électronique :{" "}
        <strong>contact@fany-glouss.fr</strong>
         <br />
         <br />
        Directeur de la  publication : <strong>Fany-glass</strong>
        <br />
        Contactez le responsable de la publication :
        <strong>contact@fany-glouss.com</strong>
      </p>

      <p>
        <strong>HÉBERGEMENT</strong>
      </p>

      <p>
        Le site est hébergé par <strong>Netlify lzrflkz 76575 ;fbvkjqbv</strong>
        <br />
        <u>
          <strong>CREDITS :</strong>
        </u>
        les mentions légales ont étés générées par
        <strong>
          <a href="https://www.generer-mentions-legales.com">
            mentions légales
          </a>
        </strong>
        <br />
        Horaires de la 
        <strong>
          <a href="http://www.patinoire.biz/p+patinoire-de-lyon---charlemagne+113.html">
            Patinoire Lyon
          </a>
        </strong>
      </p>
    </div>
    <Contacter />
    <Footer />
    <Copyrights />
  </>
)

export default Mentions
